<template>
  <div class="main">
    <headers ref="headers" :headname="headName"></headers>
    <div class="content">
      <div class="item">
        <div class="item_title">手机号：</div>
        <div>
          <span class="phone">{{ mobile }}</span>
          <el-button v-show="iscode" type="primary" class="code_btn" :disabled="isVerifyCode" @click="getCode">{{ code_text }}</el-button>
          <el-button v-show="againcode" type="primary" class="code_btn">{{ code_text }}</el-button>
        </div>
      </div>
      <div class="item">
        <div class="item_title">验证码：</div>
        <div>
          <el-input v-model="code" class="code_inp" placeholder="请输入验证码"></el-input>
          <el-button type="primary" class="code_sure" :disabled="!code.length || isVerifyCode" @click="sureCode">确认</el-button>
        </div>
      </div>
      <div v-show="code_err" class="item">
        <span class="error_msg">验证码错误</span>
      </div>
      <div v-show="isVerifyCode">
        <div class="item">
          <div class="item_title">新密码：</div>
          <div>
            <el-input v-model="newpwd" placeholder="请输入新密码" :type="flagType" class="password_inp" @blur="checkPwd(newpwd,null,false)">
              <i v-if="flag" slot="suffix" class="el-icon-view" style="margin-top:15px;margin-right:5px;font-size:18px; color:#ee2e2e;" @click="getFlag()" />
              <i v-else slot="suffix" class="el-icon-view" style="margin-top:15px;margin-right:5px;font-size:18px;" @click="getFlag()" />
            </el-input>
            <span class="span_tip_common" :class="{span_tip_red:errorTip,span_tip_grey:!errorTip}" v-html="newPassTip" />
          </div>
        </div>
        <div class="safety">
          <span class="span_safe">安全强度：</span>
          <span class="span_safe_common" :class="{span_safe_1:isLevelOne,span_safe_0:!isLevelOne}" />
          <span class="span_safe_common" :class="{span_safe_2:isLevelTwo,span_safe_0:!isLevelTwo}" />
          <span class="span_safe_common" :class="{span_safe_3:isLevelThree,span_safe_0:!isLevelThree}" />
        </div>
        <div class="item">
          <div class="item_title">确认密码：</div>
          <div>
            <el-input v-model="sureNewpwd" placeholder="请再一次输入新密码" :type="sureFlagType" class="password_inp" @blur="checkPwd(newpwd,sureNewpwd,false)">
              <i v-if="sureFlag" slot="suffix" class="el-icon-view" style="height:14px;margin-top:15px;margin-right:5px;font-size:18px; color:#ee2e2e;" @click="getFlag1()" />
              <i v-else slot="suffix" class="el-icon-view" style="margin-top:15px;margin-right:5px;font-size:18px;" @click="getFlag1()" />
            </el-input>
            <span v-show="showErrorCheckTip" class="span_tip_common span_tip_red">密码不一致，请重新输入</span>
          </div>
        </div>
        <div class="item">
          <el-button type="primary" class="save_btn" @click="savePwd">确认</el-button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div id="div_updatePwd" class="div_updatePwd">
    <p class="myclass-title">
      <span class="title-text">修改密码</span>
      <span class="title-border"></span>
    </p>
    <ul>
      <li>
        <span class="span_color">当前密码：</span>
        <label>
          <input
            v-model="paramsCommitPwd.current_pass"
            class="input"
            type="password"
            placeholder="请输入当前密码"
            autocomplete="off"
          >
        </label>
      </li>
      <li>
        <span class="span_color">新密码：</span>
        <label>
          <input
            v-model="paramsCommitPwd.new_pass"
            class="input"
            type="password"
            placeholder="请输入新密码"
            autocomplete="off"
            @blur="checkPwd(paramsCommitPwd.new_pass,null,false)"
          >
        </label>
        <span
          class="span_tip_common"
          :class="{span_tip_red:errorTip,span_tip_grey:!errorTip}"
          v-html="newPassTip"
        />
      </li>
      <div class="div_safe">
        <span class="span_safe">安全强度：</span>
        <span
          class="span_safe_common"
          :class="{span_safe_1:isLevelOne,span_safe_0:!isLevelOne}"
        />
        <span
          class="span_safe_common"
          :class="{span_safe_2:isLevelTwo,span_safe_0:!isLevelTwo}"
        />
        <span
          class="span_safe_common"
          :class="{span_safe_3:isLevelThree,span_safe_0:!isLevelThree}"
        />
      </div>
      <li>
        <span class="span_color">确认密码：</span>
        <label>
          <input
            v-model="paramsCommitPwd.check_pass"
            class="input"
            type="password"
            placeholder="请再一次输入新密码"
            autocomplete="off"
            @blur="checkPwd(paramsCommitPwd.new_pass,paramsCommitPwd.check_pass,false)"
          >
        </label>
        <span v-show="showErrorCheckTip" class="span_tip_common span_tip_red">与新密码不一致</span>

      </li>
    </ul>
    <button class="btn_ok" @click="btn_ok()">确认</button>

  </div> -->
</template>
<script>
import Cookies from 'js-cookie'
import headers from '@/components/topTitle/index.vue'
import { sendMobile, vertifyMobile, updatePwdNew } from '../../../api/mine'
import { getLoginUserInfo } from '@/api/center.js'

export default {
  components: { headers },
  data() {
    return {
      headName: '修改密码',
      uid: Cookies.get('uid') ? Cookies.get('uid') : '',
      mobile: '',
      code: '',
      newpwd: '',
      sureNewpwd: '',
      flag: false,
      flagType: 'password',
      sureFlag: false,
      sureFlagType: 'password',
      isVerifyCode: false,
      code_text: '获取验证码',
      iscode: true,
      againcode: false,
      timer: null,
      s: 60,
      code_err: false,
      userName: '',
      pwdPassRule: false,
      newPassTip: '密码由6-16(字母、数字、符号)组成，区分大小写<br>(不能是6位纯数字，不能包含空格)<br>建议使用英文字母加数字或符号的混合密码',

      errorTip: false,
      showErrorCheckTip: false,
      isChooseMan: true,
      isShowMajor: false,
      // 密码等级
      isLevelOne: false,
      isLevelTwo: false,
      isLevelThree: false,
      paramsCommitPwd: {
        current_pass: '',
        new_pass: '',
        check_pass: ''
      }
    }
  },
  watch: {
    'newpwd'(newValue) {
      if (newValue.length < 6) {
        this.isLevelOne = false
        this.isLevelTwo = false
        this.isLevelThree = false
        return
      }
      let lv = -1
      if (newValue.match(/[a-z]/ig)) {
        lv++
      }
      if (newValue.match(/[0-9]/ig)) {
        lv++
      }
      if (newValue.match(/(.[^a-z0-9])/ig)) {
        lv++
      }
      if (newValue.length < 6 && lv > 0) {
        lv--
      }
      switch (lv) {
        case 0:
          this.isLevelOne = true
          this.isLevelTwo = false
          this.isLevelThree = false
          break
        case 1:
          this.isLevelOne = false
          this.isLevelTwo = true
          this.isLevelThree = false
          break
        case 2:
          this.isLevelOne = false
          this.isLevelTwo = false
          this.isLevelThree = true
          break
      }
    }
  },
  created() {
    this.funcGetInfo()
  },
  methods: {
    funcGetInfo() {
      getLoginUserInfo().then(res => {
        if (res.errNo === 0) {
          this.mobile = res.result.mobile
          this.userName = res.result.username
        } else if (res.errNo == 2001) {
          this.loginAgain()
        }
      })
    },
    getFlag() {
      this.flag = !this.flag
      this.flagType = this.flag ? 'text' : 'password'// text为显示密码；password为隐藏密码
    },
    getFlag1() {
      this.sureFlag = !this.sureFlag
      this.sureFlagType = this.sureFlag ? 'text' : 'password'
    },
    getCode() {
      // 获取验证码
      this.iscode = false
      this.againcode = true
      this.gettime()
      sendMobile({ uid: this.uid }).then(res => {
        console.log('成功')
      })
    },
    sureCode() {
      // 验证码验证
      const info = {
        uid: this.uid,
        code: this.code
      }
      vertifyMobile(info).then(res => {
        if (res.errNo === 0) {
          this.isVerifyCode = true
          this.code_err = false
        } else {
          this.isVerifyCode = false
          this.code_err = true
        }
      })
    },
    savePwd() {
      this.checkPwd(this.newpwd, this.sureNewpwd, true)
      if (!this.showErrorCheckTip && !this.errorTip) {
        const info = {
          uid: this.uid,
          newpwd: this.newpwd
        }
        updatePwdNew(info).then(res => {
          if (res.errNo === 0) {
            this.$message.success('修改密码成功，需要重新登录')
            this.loginAgain()
          } else {
            this.$message.error(res.msg)
          }
          this.$router.go(0)
        })
      }
    },
    // 重新登录
    loginAgain() {
      const url = window.location.href
      const login_url = `${window.location.origin}/ucenter/login?redirect=${url}`
      window.location.href = login_url
    },
    gettime() {
      this.timer = setInterval(() => {
        // 某些定时器操作
        this.s--
        // this.code_text = '重发(' + this.s + ')'
        this.code_text = this.s + 's'
        if (this.s <= 0) {
          this.iscode = true
          this.againcode = false
          this.code_text = '重新获取'
          clearInterval(this.timer)
          this.s = 60
        }
      }, 1000)
    },

    // 弹窗提示
    successTip(msg) {
      this.$message({
        message: msg,
        type: 'success'
      })
    },
    warningTip(msg) {
      this.$message({
        message: msg,
        type: 'warning'
      })
    },
    btn_ok() {
      this.checkPwd(this.paramsCommitPwd.new_pass, this.paramsCommitPwd.check_pass, true)
      if (!this.showErrorCheckTip && !this.errorTip) {
        // updatePwd(this.paramsCommitPwd).then((res) => {
        //   if (parseInt(res.errNo) === 0) {
        //     this.successTip('修改密码成功')
        //   } else {
        //     if (res.msg != null && res.msg != '') {
        //       this.warningTip(res.msg)
        //     }
        //   }
        // })
        /*
        const info = {
          uuid: Cookies.get('uuid'),
          access_token: Cookies.get('access_token'),
          o_password: this.paramsCommitPwd.current_pass,
          password: this.paramsCommitPwd.new_pass,
          r_password: this.paramsCommitPwd.check_pass
        }
        changePwd(info).then(res => {
          if (parseInt(res.errNo) === 0) {
            this.successTip('修改密码成功')
          } else {
            if (res.msg != null && res.msg != '') {
              this.warningTip(res.msg)
            }
          }
        })
        */
      }
    },
    checkPwd(newPass, checkPass, tag) {
      if ((checkPass != null && checkPass.toString().trim().length > 0) || tag === true) {
        this.showErrorCheckTip = checkPass !== newPass
      }
      if (checkPass == null || tag === true) {
        this.pwdPassRule = false
        if (newPass.length > 0) {
          if (newPass.length < 6) {
            this.errorTip = true
            this.newPassTip = '密码至少包含6个字符'
          } else if (newPass.length > 16) {
            this.errorTip = true
            this.newPassTip = '密码不能超过16位'
          } else if (!isNaN(newPass)) {
            this.errorTip = true
            this.newPassTip = '密码不能是纯数字'
          } else if (/^(000000|111111|222222|333333|444444|555555|666666|777777|888888|999999)/.test(newPass)) {
            this.errorTip = true
            this.newPassTip = '密码前六位不能是相同的数字'
          } else if (newPass === this.userName) {
            this.errorTip = true
            this.newPassTip = '密码不能和用户名相同'
          } else if (newPass === this.paramsCommitPwd.current_pass) {
            this.errorTip = true
            this.newPassTip = '密码不能和旧密码相同'
          } else if (!(/^\S*$/.test(newPass))) {
            this.errorTip = true
            this.newPassTip = '密码不能包含空格'
          } else {
            this.pwdPassRule = true
            this.errorTip = false
            this.newPassTip = '密码由6-16(字母、数字、符号)组成，区分大小写<br>(不能是6位纯数字，不能包含空格)<br>建议使用英文字母加数字或符号的混合密码'
          }
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.main{
  width: 965px;
	min-height: 900px;
  padding: 0 0 20px 0;
  font-family: PingFangSC-Regular;
}
.content{
  padding: 40px 0 50px 10px;
  // background-color: aquamarine;
  .item{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    input::placeholder{
      color: #999;
    }
  }
  .item_title{
    color: #999;
    font-size: 14px;
    width: 100px;
    height: 46px;
    line-height: 46px;
    text-align: right;
    // background-color: #00a0e9;
  }
  .phone{
    min-width: 102px;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    letter-spacing: 0;
  }
  .code_btn{
    width: 110px;
    border-radius: 0;
    height: 35px;
    line-height: 10px;
    margin-left: 10px;
    background-color: #fff;
    color: #ee2e2e;
  }
  .code_inp{
    width: 188px;
    height: 46px;
    /deep/.el-input__inner{
      border-radius: 0;
      height: 46px;
      line-height: 46px;
    }
  }
  .code_sure,.save_btn{
    width: 110px;
    border-radius: 0;
    height: 46px;
    margin-left: 10px;
  }
  .save_btn{
    margin-top: 30px;
    margin-left: 100px;
  }
  .error_msg{
    margin-left: 100px;
    margin-top: -20px;
    color: #ee2e2e;
    font-size: 14px;
  }
  .password_inp{
    width: 300px;
    height: 46px;
    /deep/.el-input__inner{
      border-radius: 0;
      height: 46px;
      line-height: 46px;
    }
  }
  .safety{
    height: 21px;
    width: 180px;
    margin: 0 0 30px 100px;
    .span_safe {
      width: 61px;
      height: 12px;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
  .span_tip_common {
    height: 46px;
    position: absolute;
    margin-left: 20px;
    font-size: 12px;
    width: 300px;
    text-align: left;
  }
}

    .div_updatePwd li {
        position: relative;
        width: 100%;
        height: 46px;
        margin-top: 25px;
        text-align: left;
    }

    .span_color {
        display: inline-block;
        line-height: 46px;
        width: 110px;
        color: #999999;
        text-align: right;
        margin-right: 15px;
        font-size: 14px;
    }

    .span_tip_common {
        height: 46px;
        position: absolute;
        margin-left: 20px;
        font-size: 12px;
        width: 300px;
    }

    .span_tip_red {
        font-size: 14px;
        color: #ee2e2e;
        line-height: 46px;

    }

    .span_tip_grey {
        color: #999999;
    }

    .input {
        width: 302px;
        height: 46px;
        background-color: #ffffff;
        border: solid 1px #cfcfcf;
        padding-left: 25px;
        outline: none;
    }

    .div_updatePwd button {
        width: 90px;
        height: 30px;
        background-color: #ee2e2e;
        border-radius: 6px;
        margin-top: 57px;
        color: white;
        cursor: pointer;
        margin-left: 124px;
    }

    .btn_ok {
        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-right: 1000px;
    }

    .span_safe {
        width: 61px;
        height: 12px;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .div_safe {
        height: 21px;
        width: 180px;
        margin-left: 124px;
        margin-top: 22px;
    }

    .span_safe_common {
        display: inline-block;
        width: 36px;
        height: 6px;
        margin-bottom: 2px;
    }

    .span_safe_0 {
        background-color: #dfdfdf;
    }

    .span_safe_1 {
        background-color: #f14f4f;
    }

    .span_safe_2 {
        background-color: #00a0e9;
    }

    .span_safe_3 {
        background-color: lightgreen;
    }

</style>
